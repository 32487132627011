exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-gallery-index-jsx": () => import("./../../../src/pages/media/gallery/index.jsx" /* webpackChunkName: "component---src-pages-media-gallery-index-jsx" */),
  "component---src-pages-media-index-jsx": () => import("./../../../src/pages/media/index.jsx" /* webpackChunkName: "component---src-pages-media-index-jsx" */),
  "component---src-templates-mdx-cover-jsx-content-file-path-content-pages-test-mdx": () => import("./../../../src/templates/mdx-cover.jsx?__contentFilePath=/opt/build/repo/content/pages/test.mdx" /* webpackChunkName: "component---src-templates-mdx-cover-jsx-content-file-path-content-pages-test-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-content-projects-index-mdx": () => import("./../../../src/templates/mdx-default.jsx?__contentFilePath=/opt/build/repo/content/projects/index.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-content-projects-index-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-content-projects-project-test-mdx": () => import("./../../../src/templates/mdx-default.jsx?__contentFilePath=/opt/build/repo/content/projects/project-test.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-content-projects-project-test-mdx" */),
  "component---src-templates-mdx-page-jsx-content-file-path-content-pages-markdown-mdx": () => import("./../../../src/templates/mdx-page.jsx?__contentFilePath=/opt/build/repo/content/pages/markdown.mdx" /* webpackChunkName: "component---src-templates-mdx-page-jsx-content-file-path-content-pages-markdown-mdx" */)
}

